<template>
   <div class="footerBox">
        <!-- <img
          class="messageImg"
          v-for="item in list"
          :key="item"
          :src="item.img"
          alt=""
        /> -->
        <img class="sixImg" :src="sixImg" alt="" ondragstart="return false;" oncontextmenu="return false;">
      </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name:"message",
    data(){
        return{
             list: [
        { img: require("@/assets/首页/研学营.png"), title: "1" },
        { img: require("@/assets/首页/研学营.png"), title: "1" },
        { img: require("@/assets/首页/研学营.png"), title: "1" },
        { img: require("@/assets/首页/研学营.png"), title: "1" },
        { img: require("@/assets/首页/研学营.png"), title: "1" },
        { img: require("@/assets/首页/研学营.png"), title: "1" },
        // { img: require("@/assets/首页/研学营.png"), title: "1" },
        // { img: require("@/assets/首页/研学营.png"), title: "1" },

      ],
      sixImg: require("@/assets/1new/首页/新生涯网站首页_34.jpg"),

        }
    }
})
</script>

<style lang="scss" scoped>
.footerBox{
  // background-color: #fff;
}
  .messageImg {
    width: 388px;
    height: 128px;
    margin-left: 8px;
    margin-bottom: 8px;
  }
  .sixImg{
    width: 1200px;
    height: 274px;
  }
</style>